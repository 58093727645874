<template>
  <div class="container" ref="homeContainer">
    <section class="hero" id="hero-section">
      <div class="hero__headline">
        <h1>{{ $t("meetOurTeemLbl") }}</h1>
      </div>
      <div class="hero__headline_2">
        <h1>{{ $t("teamDesc") }}</h1>
      </div>
    </section>
    <section class="team">
      <div class="team__headline">
        <h1>{{ $t("ourTeamLbl") }}</h1>
      </div>
      <div
        class="expandController"
        v-for="(pos, propertyName, index) in team"
        :key="index"
      >
        <div class="expandBtn" @click="expandTeam(index)">
          <h1 class="roleLbl">{{ propertyName }}</h1>
          <div class="lineContainer">
            <span class="line"></span>
            <span
              class="arrow-down div-background-img"
              :class="rotateIcon(index)"
            ></span>
          </div>
        </div>
        <transition name="fade">
          <div class="containerCard" v-if="expand[index]">
            <div class="card" v-for="emp in pos" :key="emp.id">
              <div class="imgBx">
                <img :src="emp.imgSrc" alt="" />
              </div>
              <div class="content">
                <div class="contentBx">
                  <h3>
                    {{ emp.name }} <br /><span class="emp_position">{{
                      emp.position
                    }}</span>
                  </h3>
                </div>
                <!-- <ul class="sci">
                  <li style="--i: 1">
                    <a href="#"
                      ><i class="fa fa-instagram" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li style="--i: 2">
                    <a href="#"
                      ><i class="fa fa-github" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li style="--i: 3">
                    <a href="#"
                      ><i class="fa fa-linkedin" aria-hidden="true"></i
                    ></a>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
    <section class="offersSection" id="hero-section-3">
      <div class="offersSection__headline_likeUs">
        <h1>{{ $t("doYouLikeUsLbl") }}</h1>
      </div>
      <div class="offersSection__headline_Team">
        <h1>{{ $t("joinOurTeamLbl") }}</h1>
      </div>
      <div class="offersSection__see-all">
        <button
          class="btn-primary btn-primary-right-lg"
          @click="$router.replace('/jobs')"
        >
          <span></span>
          <p>{{ $t("checkOutOffersLbl") }}</p>
          <div></div>
        </button>
      </div>
    </section>
    <section class="teamImgSection">
      <div class="teamImgSection__img-wrapper">
        <img class="teamImgSection__img" src="../assets/arch.png" alt="" />
      </div>
    </section>
    <section class="footer" id="footer-section">
      <div class="footer__headline">
        <h1>spinnov</h1>
      </div>
      <!-- <div class="footer__social-media">
        <div
          class="footer__social-media__item"
          v-for="option in socialMediaOptions"
          :key="option.id"
        >
          <div
            class="div-background-img"
            :style="{
              backgroundImage:
                'url(' + require(`../assets/${option.image}`) + ')',
            }"
          ></div>
        </div>
      </div> -->
      <div class="footer__headline_2">
        <h1 v-if="isWindowWidthDesktop">
          De Run 4547 | 5503 LT Veldhoven
        </h1>
        <h1 v-else>
          De Run 4547 <br />
          5503 LT Veldhoven
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
import { teamMembers } from "../variables/team.js";
import EventBus from "../event-bus";
import Vue from "vue";
export default {
  data() {
    return {
      inWidth: 0,
      expand: [],
      socialMediaOptions: [
        {
          id: 0,
          link: "",
          image: "facebookTeam.svg",
        },
        {
          id: 1,
          link: "",
          image: "instagramTeam.svg",
        },
        {
          id: 2,
          link: "",
          image: "twitterTeam.svg",
        },
        {
          id: 3,
          link: "",
          image: "youtubeTeam.svg",
        },
        {
          id: 4,
          link: "",
          image: "linkedinTeam.svg",
        },
      ],
      team: teamMembers,
    };
  },
  computed: {
    isWindowWidthDesktop() {
      if (this.inWidth <= 600) {
        return false;
      }
      return true;
    },
  },
  methods: {
    onScrollHandler(event) {
      if (this.resizedSmallerScreen) {
        window.scrollTo(0, 1);
      }
      let x = (event.target.scrollTop / 10) % Math.PI;
      EventBus.$emit("pageScrolled", { theta: x });
    },
    rotateIcon(id) {
      if (this.expand[id]) {
        return "rotate_arrow";
      } else {
        return "";
      }
    },
    expandTeam(id) {
      Vue.set(this.expand, id, !this.expand[id]);
    },
    updateInnerWidth() {
      this.inWidth = window.innerWidth;
    },
  },
  mounted() {
    if (this.$refs.homeContainer) {
      this.$refs.homeContainer.addEventListener("scroll", this.onScrollHandler);
    }
    window.addEventListener("resize", this.updateInnerWidth);
    this.updateInnerWidth();
    for (let index = 0; index < Object.keys(this.team).length; index++) {
      this.expand[index] = true;
    }
  },
  created() {
    let ckeditor = document.createElement("script");
    ckeditor.setAttribute("src", "https://use.fontawesome.com/0c4c043ffa.js");
    document.head.appendChild(ckeditor);
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";
@import "../scss/_mixins.scss";

.container {
  height: calc(100vh - 115.2px);
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond(laptop) {
    height: calc(100vh - 108px);
  }
  @include respond(small-laptop) {
    height: calc(100vh - 101.73px);
  }
  @include respond(tablet-land) {
    height: calc(100vh - 96px);
  }

  @include respond(tablet-port) {
    height: calc(100vh - 72px);
  }
}
.hero {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem 0 0rem;
  background-image: url("../assets/red_back.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include respond(laptop) {
    padding: 5rem 0rem 0 0rem;
  }

  @include respond(small-laptop) {
    padding: 5rem 0rem 0 0rem;
  }

  @include respond(tablet-land) {
    padding: 5rem 0rem 0 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    padding: 5rem 0rem 0 0rem;
  }
  &__headline_2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 50%;
    margin-top: 5rem;
    padding-bottom: 8rem;

    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 2.4rem;
      font-weight: 300;
      opacity: 0.8;
      text-align: left;
      color: #ffffff;
      text-align: center;
      @include respond(desktop) {
        font-size: 2.4rem;
      }

      @include respond(laptop) {
        font-size: 2.2rem;
      }

      @include respond(small-laptop) {
        font-size: 2.1rem;
      }

      @include respond(tablet-land) {
        font-size: 2rem;
      }

      @include respond(phone-land) {
        font-size: 2rem;
      }
    }
  }

  &__headline {
    & > h1 {
      font-family: "Raleway";
      font-size: 8rem;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      @include respond(desktop) {
        font-size: 8rem;
      }

      @include respond(laptop) {
        font-size: 7rem;
      }

      @include respond(small-laptop) {
        font-size: 6rem;
      }

      @include respond(tablet-land) {
        font-size: 5rem;
      }

      @include respond(phone-land) {
        font-size: 4rem;
      }
    }
  }

  &__headline_applyNow {
    margin: 0 auto;
    width: 50%;

    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 3.2rem;
      font-weight: bold;
      text-align: left;
      color: $color-gray-4;

      @include respond(desktop) {
        font-size: 3.2rem;
      }

      @include respond(laptop) {
        font-size: 3rem;
      }

      @include respond(small-laptop) {
        font-size: 2.8rem;
      }

      @include respond(tablet-land) {
        font-size: 2.6rem;
      }

      @include respond(phone-land) {
        font-size: 2.6rem;
      }
    }
  }

  &__text {
    margin-bottom: -5rem;
    margin-left: -10rem;

    @include respond(laptop) {
      margin-left: -9rem;
    }

    @include respond(small-laptop) {
      margin-left: -12rem;
    }

    @include respond(tablet-land) {
      margin-left: -14rem;
    }

    @include respond(tablet-port) {
      margin-left: 0;
      margin-top: 3rem;
    }

    & > p {
      opacity: 0.6;
      font-family: "Open Sans";
      font-size: 2.4rem;
      line-height: 1.42;

      @include respond(desktop) {
        font-size: 2.3rem;
      }

      @include respond(laptop) {
        font-size: 2.1rem;
      }

      @include respond(small-laptop) {
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        font-size: 1.8rem;
      }

      @include respond(phone-land) {
        font-size: 1.6rem;
      }
    }
  }

  &__btn-container {
    margin-left: auto;
    margin-right: -15.5rem;
    transform: rotate(-90deg);

    @include respond(laptop) {
      margin-right: -12.5;
    }

    @include respond(phone-land) {
      margin-right: -10rem;
      margin-top: 15rem;
    }
  }

  &__animation {
    margin-top: 5rem;

    @include respond(desktop) {
      margin-top: 4rem;
    }

    @include respond(laptop) {
      margin-top: 3rem;
    }

    @include respond(small-laptop) {
      margin-top: 2rem;
    }

    @include respond(tablet-land) {
      margin-top: 1rem;
    }

    @include respond(phone-land) {
      display: none;
    }
  }
}
.formSection {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 30rem 15rem 15rem 15rem;

  @include respond(laptop) {
    margin: 25rem 12rem 15rem 12rem;
  }

  @include respond(small-laptop) {
    margin: 20rem 10rem 15rem 10rem;
  }

  @include respond(tablet-land) {
    margin: 15rem 0rem 10rem 0rem;
  }
  @include respond(phone-land) {
    margin: 7rem 0rem 8rem 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__headline_likeUs {
    & > h1 {
      font-family: "Open Sans";
      font-size: 8rem;
      font-weight: bold;
      text-align: center;

      @include respond(desktop) {
        font-size: 8rem;
      }

      @include respond(laptop) {
        font-size: 8rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(phone-land) {
        font-size: 4.5rem;
      }
    }
  }
  &__headline_Team {
    & > h1 {
      font-family: "Open Sans";
      font-size: 6.4rem;
      font-weight: 600;
      text-align: center;

      @include respond(desktop) {
        font-size: 6.4rem;
      }

      @include respond(laptop) {
        font-size: 6.4rem;
      }

      @include respond(small-laptop) {
        font-size: 5.2rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }

  &__headline_form {
    & > h1 {
      font-family: "Open Sans";
      font-size: 4.8rem;
      font-weight: bold;
      text-align: center;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 4.8rem;
      }

      @include respond(laptop) {
        font-size: 4.5rem;
      }

      @include respond(small-laptop) {
        font-size: 4rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }
}
.footer-section {
  scroll-snap-align: center;
}

.team {
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 15rem;
  @include respond(desktop) {
    margin-top: 15rem;
  }

  @include respond(laptop) {
    margin-top: 15rem;
  }

  @include respond(small-laptop) {
    margin-top: 13rem;
  }

  @include respond(tablet-land) {
    margin-top: 12rem;
  }

  @include respond(phone-land) {
    margin-top: 10rem;
  }
  &__headline {
    & > h1 {
      font-family: "Raleway";
      font-size: 4.8rem;
      font-weight: bold;
      text-align: center;
      opacity: 0.8;
      margin-bottom: 3rem;

      @include respond(desktop) {
        font-size: 4.8rem;
      }

      @include respond(laptop) {
        font-size: 4.5rem;
      }

      @include respond(small-laptop) {
        font-size: 4rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }
}

.containerCard {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 5%;
  @include respond(phone-land) {
    justify-content: center;
    align-items: center;
  }
}
.containerCard .card {
  position: relative;
  width: 261px;
  height: 350px;
  margin: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(phone-land) {
    margin-bottom: 1.5rem;
  }
}
.containerCard .card .content {
  position: absolute;
  bottom: -270px;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 10;
  flex-direction: column;
  backdrop-filter: blur(2px);
  background-color: rgba(125, 114, 228, 0.7);
  transition: bottom 0.5s;
  transition-delay: 0s;

  @include respond(phone-land) {
    align-items: center;
  }
}
.imgBx > img {
  width: 100%;
  height: 100%;
}
.containerCard .card:hover .content {
  bottom: -210px;
  transition-delay: 0s;
}
.containerCard .card .content .contentBx h3 {
  color: #fff;
  font-family: "Raleway";
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 18px;
  text-align: start;
  margin: 15px 10px 15px 10px;
  line-height: 1.1em;
  transition: 0.5s;
  transition-delay: 0.6s;
  opacity: 1;
  letter-spacing: 0;

  @include respond(laptop) {
    font-size: 18px;
  }

  @include respond(small-laptop) {
    font-size: 16px;
  }

  @include respond(tablet-land) {
    font-size: 15px;
  }
  @include respond(phone-land) {
    font-size: 14px;
    text-align: center;
  }
}
.containerCard .card .content .contentBx h3 span {
  font-size: 16px;
  font-weight: 400;
  text-transform: initial;
  @include respond(laptop) {
    font-size: 15px;
  }

  @include respond(small-laptop) {
    font-size: 14px;
  }

  @include respond(tablet-land) {
    font-size: 13px;
  }
  @include respond(phone-land) {
    font-size: 12px;
  }
}
.containerCard .card .content .sci {
  position: relative;
  bottom: 10px;
  display: flex;
}
.containerCard .card .content .sci li {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.2s * var(--i));
}

.containerCard .card:hover .content .sci li {
  transform: translateY(0);
  opacity: 1;
}
.containerCard .card .content .sci li a {
  color: white;
  font-size: 24px;
}
.offersSection {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 35rem 15rem 0 15rem;

  @include respond(laptop) {
    margin: 35rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 30rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 30rem 0rem 0 0rem;
  }
  @include respond(phone-land) {
    margin: 7rem 0rem 0 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__headline_likeUs {
    & > h1 {
      font-family: "Open Sans";
      font-size: 8rem;
      font-weight: bold;
      text-align: center;

      @include respond(desktop) {
        font-size: 8rem;
      }

      @include respond(laptop) {
        font-size: 8rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(phone-land) {
        font-size: 4.5rem;
      }
    }
  }
  &__headline_Team {
    & > h1 {
      font-family: "Open Sans";
      font-size: 6.4rem;
      font-weight: 600;
      text-align: center;

      @include respond(desktop) {
        font-size: 6.4rem;
      }

      @include respond(laptop) {
        font-size: 6.4rem;
      }

      @include respond(small-laptop) {
        font-size: 5.2rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }
  &__see-all {
    display: flex;
    justify-content: center;
    margin: 8rem auto 40rem auto;

    @include respond(tablet-port) {
      margin: 8rem auto 20rem auto;
      width: 60rem;
    }
    @include respond(phone-land) {
      margin: 8rem auto 15rem auto;
      width: 50rem;
    }
    @include respond(phone) {
      margin: 8rem auto 15rem auto;
      width: 40rem;
    }
  }
}

.teamImgSection {
  &__img-wrapper {
    width: 80%;
    justify-content: center;
    margin: 0rem auto 15rem auto;
    @include respond(phone-land) {
      width: 90%;
    }
    @include respond(phone) {
      width: 95%;
    }
  }
  &__img {
    width: 100%;
  }
}

.footer {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem 10rem 0rem;

  @include respond(laptop) {
    padding: 8rem 0rem 15rem 0rem;
  }

  @include respond(small-laptop) {
    padding: 5rem 0rem 15rem 0rem;
  }

  @include respond(tablet-land) {
    padding: 5rem 0rem 15rem 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    padding: 5rem 0rem 15rem 0rem;
  }
  &__headline_2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 50%;
    margin-top: 5rem;
    padding-bottom: 8rem;

    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 4.5rem;
      font-weight: 300;
      text-align: left;
      color: #110b47;
      opacity: 0.6;
      text-align: center;
      @include respond(desktop) {
        font-size: 4.5rem;
      }

      @include respond(laptop) {
        font-size: 4.2rem;
      }

      @include respond(small-laptop) {
        font-size: 4rem;
      }

      @include respond(tablet-land) {
        font-size: 3.8rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }

  &__headline {
    & > h1 {
      font-family: "Montserrat";
      font-size: 13.7rem;
      font-weight: 600;
      text-align: center;
      color: #110b47;
      @include respond(desktop) {
        font-size: 13.7rem;
      }

      @include respond(laptop) {
        font-size: 12rem;
      }

      @include respond(small-laptop) {
        font-size: 11rem;
      }

      @include respond(tablet-land) {
        font-size: 10rem;
      }

      @include respond(phone-land) {
        font-size: 9rem;
      }
    }
  }
  &__social-media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 50rem;
    margin: 5rem auto 5rem auto;

    @include respond(desktop) {
      margin: 5rem auto 5rem auto;
      max-width: 50rem;
    }

    @include respond(laptop) {
      margin: 5rem auto 5rem auto;
      max-width: 45rem;
    }

    @include respond(small-laptop) {
      margin: 4rem auto 4rem auto;
      max-width: 45rem;
    }

    @include respond(tablet-land) {
      margin: 4rem auto 5rem auto;
      max-width: 35rem;
    }

    @include respond(phone-land) {
      margin: 3rem auto 3rem auto;
      max-width: 30rem;
    }

    &__item {
      cursor: pointer;

      & > div {
        width: 7rem;
        height: 7rem;

        @include respond(desktop) {
          width: 7rem;
          height: 7rem;
        }

        @include respond(laptop) {
          width: 6.2rem;
          height: 6.2rem;
        }

        @include respond(small-laptop) {
          width: 6rem;
          height: 6rem;
        }

        @include respond(tablet-land) {
          width: 5.5rem;
          height: 5.5rem;
        }

        @include respond(phone-land) {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }
}
.expandBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 10rem auto;
  position: relative;
  @include respond(laptop) {
  }

  @include respond(small-laptop) {
  }

  @include respond(tablet-land) {
  }

  @include respond(phone-land) {
  }
}
.roleLbl {
  font-family: "Raleway";
  font-size: 3rem;
  opacity: 0.8;
  margin-left: 1rem;
  margin-right: 1rem;
  white-space: nowrap;

  @include respond(phone-land) {
    white-space: unset;
  }
}
.lineContainer {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond(laptop) {
    width: 90%;
  }

  @include respond(small-laptop) {
    width: 88%;
  }

  @include respond(tablet-land) {
    width: 86%;
  }
  @include respond(tablet-port) {
    width: 86%;
  }

  @include respond(phone-land) {
    width: 10%;
  }
}
.line {
  width: 95%;
  margin-top: 0.5rem;
  height: 0.1rem;
  border-radius: 1rem;
  background-color: black;

  @include respond(tablet-port) {
    width: 95%;
  }
  @include respond(phone-land) {
    display: none;
  }
}
.arrow-down {
  width: 3rem;
  height: 3rem;
  background-image: url("../assets/back.png");
  transform: rotate(-90deg);
  transition: transform 0.5s;
  margin-top: 1rem; // top and bottom are actually left and right, this is because of the rotation
  margin-bottom: 1rem;
}
.rotate_arrow {
  transform: rotate(90deg) !important;
}
.expandController {
  margin: 3rem 0rem;
}
.emp_position {
  font-size: 16px;
  color: white;
  opacity: 0.7;
}
</style>
