export const teamMembers = {
  "Management Team": [
    {
      id: 0,
      name: "Sjef Fransen",
      position: "CEO",
      imgSrc: require("../assets/teamImgs/Sjef.jpg"),
    },
    {
      id: 1,
      name: "Chris Heger",
      position: "COO",
      imgSrc: require("../assets/teamImgs/chris.png"),
    },
    {
      id: 2,
      name: "Georgette Jorritsma",
      position: "Management Assistant",
      imgSrc: require("../assets/teamImgs/Georgette.jpg"),
    },
  ],
  "System Architecture & Quality Assurance Team": [
    {
      id: 0,
      name: "Frank van den Hoef",
      position: "Technical Lead",
      imgSrc: require("../assets/teamImgs/Frank.png"),
    },
    {
      id: 1,
      name: "Bianca Mindru",
      position: "Quality Process Engineer",
      imgSrc: require("../assets/teamImgs/Bibii.png"),
    },
  ],
  "Web & App Team": [
    {
      id: 0,
      name: "Aleksandar Georgiev",
      position: "Team Lead / Front-End Developer",
      imgSrc: require("../assets/teamImgs/Alek.jpg"),
    },
    {
      id: 1,
      name: "Szabi Joczik",
      position: "Fullstack Developer",
      imgSrc: require("../assets/teamImgs/Szabi.png"),
    },
    {
      id: 2,
      name: "Fabio Salvadori",
      position: "UX/UI Designer",
      imgSrc: require("../assets/teamImgs/Fabio.png"),
    },
    {
      id: 3,
      name: "Steven Bosch",
      position: "Full-stack Developer",
      imgSrc: require("../assets/teamImgs/Steven.jpg"),
    },
    {
      id: 4,
      name: "Yordan Ivanov",
      position: "Front-end Developer",
      imgSrc: require("../assets/teamImgs/Yordan.png"),
    },
    {
      id: 5,
      name: "Mohsen Amrolahi",
      position: "Back-end Developer",
      imgSrc: require("../assets/teamImgs/Mohsen.png"),
    },
    {
      id: 6,
      name: "Daan Janssen",
      position: "Full-stack Developer",
      imgSrc: require("../assets/teamImgs/Daan_Janssen.png"),
    }
  ],
  "Embedded Software Team": [
    {
      id: 0,
      name: "André van Schoubroeck",
      position: "Senior Embedded Software Engineer",
      imgSrc: require("../assets/teamImgs/Andre.png"),
    },
    {
      id: 1,
      name: "Andrik Schrijver",
      position: "Embedded Software Engineer",
      imgSrc: require("../assets/teamImgs/Andrik.png"),
    },
    {
      id: 2,
      name: "Ilker Chilmioglou",
      position: "Embedded Software Engineer",
      imgSrc: require("../assets/teamImgs/Ilker.png"),
    },
    {
      id: 3,
      name: "Ryan Vrösch",
      position: "Embedded Software Engineer",
      imgSrc: require("../assets/teamImgs/ryan.png"),
    },
  ],
  "Project Management": [
    {
      id: 0,
      name: "Rogier Thus",
      position: "Project Manager",
      imgSrc: require("../assets/teamImgs/rogier.png"),
    },
  ],
  "Electrical Engineering Team": [
    {
      id: 0,
      name: "Leon Lepoutre",
      position: "Team Lead / Electrical Engineer",
      imgSrc: require("../assets/teamImgs/Leon.jpg"),
    },
    {
      id: 1,
      name: "Luigi Gjergjaj",
      position: "Electrical Engineer",
      imgSrc: require("../assets/teamImgs/luigi.png"),
    },
    {
      id: 2,
      name: "Pietro Fumolo",
      position: "Electrical Engineer",
      imgSrc: require("../assets/teamImgs/Pietro.png"),
    }
  ],
  "Mechatronics & Industrial Design": [
    {
      id: 0,
      name: "Dimitar Lozanov",
      position: "Mechatronics Engineer",
      imgSrc: require("../assets/teamImgs/dimitar.png"),
    },
    {
      id: 0,
      name: "Guido Hagen",
      position: "Industrial Design",
      imgSrc: require("../assets/teamImgs/Guido.png"),
    },
  ],
  "Sales / Marketing": [
    {
      id: 0,
      name: "Krisztian Gyuró",
      position: "Sales And Marketing Specialist",
      imgSrc: require("../assets/teamImgs/kris.png"),
    },
  ],
  "Advisory Board": [
    {
      id: 1,
      name: "Mark Gerritsen",
      position: "",
      imgSrc: require("../assets/teamImgs/Gark.png"),
    },
    {
      id: 2,
      name: "Anton van Limpt",
      position: "",
      imgSrc: require("../assets/teamImgs/anton.png"),
    },
    {
      id: 3,
      name: "Hans van Limpt",
      position: "",
      imgSrc: require("../assets/teamImgs/hanns.png"),
    },
  ],
};
